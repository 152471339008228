<template>
  <div>
    <div style="display: flex;">
      <div class="mr3">
        <el-form>
          <el-form-item :error="getErrorMessage('value')">
            <el-input
              ref="value"
              v-model="form.value"
              size="large"
              @blur="v$.form.value.$touch()"
            />
          </el-form-item>
        </el-form>
      </div>

      <el-button :text="!isFieldChanged" :type="isValid ? 'primary' : 'danger'" :loading="loading" :disabled="!isFieldChanged || !isValid || allowSubmit" @click="chooseMethod()">
        <el-icon v-if="isValid">
          <Select />
        </el-icon>
        <el-icon v-else>
          <CloseBold />
        </el-icon>
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  ElMessage,
} from 'element-plus';
import { useVuelidate } from '@vuelidate/core';
import {
  helpers, minLength, maxLength,
} from '@vuelidate/validators';

const passNumbers = (value) => !helpers.req(value) || value.match(/[0-9]/);
const passAlpha = (value) => !helpers.req(value) || value.match(/[а-яa-z]/);
const passAlphaUpper = (value) => !helpers.req(value) || value.match(/[А-ЯA-Z]/);
// const passSymbols = (value) => !helpers.req(value) || value.match(/!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\?/); //eslint-disable-line
// const passNumbers = (value) => !helpers.req(value) || value.match(/[0-9]/);
// const passNumbers = (value) => !helpers.req(value) || value.match(/[0-9]/);

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: 'UsersChangeField',
  props: {
    type: String,
    item: Object,
  },
  data: () => ({
    form: {
      value: '',
    },
    loading: false,
  }),
  mounted() {
    if (this.type === 'name') {
      this.form.value = this.item.pseudonym;
    }
  },
  watch: {
    'item.id': function () {
      if (this.type === 'name') {
        this.form.value = this.item.pseudonym;
      }
    },
  },
  computed: {
    allowSubmit() {
      return this.v$.$invalid;
    },
    isValid() {
      return this.form.value;
    },
    isFieldChanged() {
      return this.form.value !== this.item.pseudonym;
    },
  },
  validations() {
    if (this.type === 'pass') {
      return {
        form: {
          value: {
            passNumbers, passAlpha, minLength: minLength(6), maxLength: maxLength(64), passAlphaUpper,
          },
        },
      };
    }
    return { form: {} };
  },

  methods: {
    getErrorMessage(fieldName) {
      return this.$errorsMessages(this.v$, `form.${fieldName}`);
    },
    chooseMethod() {
      if (this.type === 'pass') {
        this.changePass(this.form.value);
      } else {
        this.changeName(this.form.value);
      }
    },
    async changePass(newPassword) {
      try {
        const params = {
          userEmail: this.item.email,
          newPassword,
        };
        await this.$axios.put('/user/reset-password', params);
        ElMessage({ message: 'Пароль успешно изменен', type: 'success' });
        this.$emit('get-users-list');
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async changeName(pseudonym) {
      try {
        await this.$axios.post(`/user/change-pseudonym?userId=${this.item.id}&pseudonym=${pseudonym}`);
        ElMessage({ message: 'Имя успешно изменено', type: 'success' });
        this.$emit('get-users-list');
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>
