<template>
  <div>
    <UsersTable />
  </div>
</template>

<script>
import UsersTable from '@/components/tables/UsersTable.vue';

export default {
  name: 'Users',
  components: {
    UsersTable,
  },
  data: () => ({

  }),
};
</script>
